import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import Header from '../Header'
import MobileBottomImages from '../MobileBottomImages'

const ServicesPageTemplate = ({
  meta_title,
  meta_description,
  services,
}) => (
  <StaticQuery
    query={graphql`
      query ServicesImgQuery {
        image1: file(
          relativePath: {
            eq: "image-leistungen_01.jpg"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image2: file(
          relativePath: {
            eq: "image-leistungen_02.jpg"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <div>
        <Header
          meta_title={meta_title}
          meta_description={meta_description}
          heading={services.heading}
          breadcrumb='HOME > LEISTUNGEN'
        />
        <section className='section section--gradient without-vertical-padding'>
          <div className='container anthracite-text'>
            <div className='section without-vertical-padding'>
              <div className='columns is-desktop'>
                <div className='column  is-three-quarters-tablet is-half-desktop'>
                  <div className='content'>
                    <h3 className='light'>{services.description}</h3>
                    <div>
                      <ul>
                        {services.items_left.map(item => (
                          <li key={item.title}>{item.title}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='column is-hidden-touch'>
                  <Img fluid={data.image1.childImageSharp.fluid} alt='' />
                </div>
              </div>
            </div>
            <div className='section'>
              <div className='columns'>
                <div className='column is-hidden-touch'>
                  <Img fluid={data.image2.childImageSharp.fluid} alt='' />
                </div>
                <div className='column  is-three-quarters-tablet is-half-desktop'>
                  <div className='content no-mobile-padding-top'>
                    <div>
                      <ul>
                        {services.items_right.map(item => (
                          <li key={item.title}>{item.title}</li>
                        ))}
                      </ul>
                    </div>
                    <h3 className='light'>{services.call_to_action}</h3>
                    <p className='anthracite-text'>
                      <span className='bold'>{services.cta_description_bold}</span>{' '}
                      {services.cta_description_regular}<br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <MobileBottomImages image1={data.image1} image2={data.image2} />
          </div>
        </section>
      </div>
    )}
  />
)

ServicesPageTemplate.propTypes = {
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  services: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    items_left: PropTypes.array,
    items_right: PropTypes.array,
    call_to_action: PropTypes.string,
    cta_description_bold: PropTypes.string,
    cta_description_regular: PropTypes.string,
  }),
}

export default ServicesPageTemplate
