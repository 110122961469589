import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ServicesPageTemplate from '../components/ServicesPageTemplate'
import Layout from '../components/Layout'

const ServicesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ServicesPageTemplate
        title={frontmatter.title}
        meta_title={frontmatter.meta_title}
        meta_description={frontmatter.meta_description}
        services={frontmatter.services}
      />
    </Layout>
  )
}

ServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ServicesPage

export const servicesPageQuery = graphql`
  query ServicesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        meta_title
        meta_description
        services {
          heading
          description
          items_left {
            title
          }
          items_right {
            title
          }
          call_to_action
          cta_description_bold
          cta_description_regular
        }
      }
    }
  }
`
